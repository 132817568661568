<template>
  <div class="root">
    <div>
      <div class="button-add">
      <el-button color="primary" @click="tagAdd()">添加</el-button>
      </div>
      <div class="list-box">
        <el-table :data="tags" border>
          <el-table-column label="ID" prop="id" width="100"></el-table-column>
          <el-table-column label="NAME" prop="name" width="200"></el-table-column>
          <el-table-column label="CREATED" prop="created_at" width="200"></el-table-column>
          <el-table-column label="PUDATED" prop="updated_at" width="200" ></el-table-column>
          <el-table-column label="OPS" width="200">
            <template  slot-scope="scope" >
              <el-button
                type="warning"
                @click="tagEdit(scope.row)">EDIT</el-button>
              <el-button
                type="danger"
                @click="tagDel(scope.row)">DELETE</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 添加tag -->
    <el-dialog
      title="添加tag"
      :visible.sync="openAdd"
      width="30%"
      :before-close="handleClose">
      <div style="padding: 24px;">
        <el-form :model="tag" class="mu-demo-form" label-position="left" label-width="100">
          <el-form-item label="名称">
            <el-input v-model="tag.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openAdd = false">取 消</el-button>
        <el-button type="primary" @click="submitTag">提交</el-button>
      </span>
    </el-dialog>

    <!-- 修改tag -->
    <el-dialog
      title="添加tag"
      :visible.sync="openEdit"
      width="30%"
      :before-close="handleClose">
      <div style="padding: 24px;">
        <el-form :model="tag" class="mu-demo-form" label-position="left" label-width="100">
          <el-form-item label="名称">
            <el-input v-model="tag.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openEdit = false">取 消</el-button>
        <el-button type="primary" @click="submitEdit">提交修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'AdminTag',
  data () {
    return {
      tag: {
        id: '',
        name: ''
      },
      sort: {
        name: '',
        order: 'asc'
      },
      tags: [],
      openEdit: false,
      openAdd: false
    }
  },
  methods: {
    tagAdd () {
      this.openAdd = true
    },
    getTags () {
      this.$axios.get('/api/tag')
        .then(res => { this.tags = res.data })
        .then(err => console.log(err))
    },
    submitTag () {
      this.$axios.post('/api/tag', this.tag)
        .then(res => {
          this.tags.push(res.data)
          this.openAdd = false
        })
    },
    tagEdit (item) {
      this.openEdit = true
      console.log(item)
      this.tag.id = item.id
      this.tag.name = item.name
    },
    submitEdit () {
      this.$axios.put('/api/tag/' + this.tag.id, this.tag)
        .then(res => {
          this.tags.splice(this.tags.findIndex(el => { return el.id === res.data.id }),
            1, res.data)
          this.tag = {
            id: '',
            name: ''
          }
          this.openEdit = false
        })
    },
    tagDel (item) {
      this.$axios.delete('/api/tag/' + item.id)
        .then(() => {
          this.tags.splice(this.tags.findIndex(el => el.id === item.id), 1)
        })
    },
    handleClose () {}
  },
  mounted () {
    this.getTags()
  }
}
</script>
<style lang="stylus" scoped>
.root
  margin: 20px auto
  .button-add
    margin: 10px
  .list-box
    padding 20px
</style>
